@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "HelveticaWorldBold";
    src: url("./assets/fonts/HelveticaWorldBold.ttf") format("truetype");
}
@font-face {
    font-family: "PublicPixel";
    src: url("./assets/fonts/PublicPixel.ttf") format("truetype");
}
@font-face {
    font-family: "RiftRegular";
    src: url("./assets/fonts/RiftRegular.otf") format("opentype");
}
@font-face {
    font-family: "RiftBold";
    src: url("./assets/fonts/RiftBold.otf") format("opentype");
}
/* Define basic font settings and color variables */
#tr__fluid__anim {
    background-color: transparent !important;
}
html {
    scroll-behavior: smooth;
}
/* Width */
/* ::-webkit-scrollbar {
    width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #bf3131;
  
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #f9f9f9;

} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #5a2616;
} */
.custom-cursor {
    /* Assuming 'cursor.png' is in the same directory as your CSS */
    cursor: url("../src/assets/icons/magnify.svg") 4 4, auto; /* Hotspot coordinates are set to (4, 4) */
}
/* Hide scrollbar for WebKit browsers */
.background-paper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.background-paper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

:root {
    /* Font weights */
    --font-weight-regular: 400;
    --font-weight-bold: 700;

    /* Color for headers and texts */
    --header-color: #000; /* Black for headers */
    --text-color: #dceaea; /* Slightly gray for regular text */

    /* Dark/Light mode colors */
    --text-color-light: #1d1d1d; /* Dark text for light background */
    --text-color-dark: #e0eeee; /* Light text for dark background */
    --background-color-light: #e7dec2; /* Light background */
    --background-color-dark: #121315; /* Dark background */

    /* Header colors for dark/light mode */
    --header-color-light: #bf5331; /* Black for headers in light mode */
    --header-color-dark: #ccc; /* Light gray for headers in dark mode */
}

body {
    font-family: "HelveticaWorldBold";
    overflow: hidden;
    background-color: var(--background-color-light);
    color: var(--text-color-light);
    -webkit-overflow-scrolling: touch;
    filter: saturate(165%);
    scroll-behavior: smooth;
}

/* Headings (h1 - h6) */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--header-color); /* Color for headers */
    font-weight: var(--font-weight-bold); /* Bold font for headers */
}
.h10 {
  font-family: "Russo One", sans-serif;

}
.h11 {
   font-family: "Russo One", sans-serif;
}
.h12 {
   font-family: "PublicPixel", sans-serif;
}
h1 {
    font-size: 6em;
    font-family: "Russo One", sans-serif;
    letter-spacing: 5px;
}
h2 {
    font-size: 4em;
    letter-spacing: 3px;
    font-family: "Russo One", sans-serif;
}
h3 {
    font-size: 5em;
}
h4 {
    font-size: 1.5em;
    color: #1d1d1d;
}
h5 {
    font-size: 1.25em;
}
h6 {
    font-size: 1em;
    font-family: "Times New Roman", Times, serif;
}
h7 {
    font-size: 120px;
    font-family: "Tangerine", cursive;
}
.h10 {
    font-family: "Russo One", sans-serif;
}
/* Paragraph */
p {
    color: var(--text-color); /* Slightly gray color for paragraph */

    line-height: 1.5;
    font-weight: var(--font-weight-regular); /* Regular font for paragraph */
}

/* Span */
span {
    color: var(--text-color);
    color: #1d1d1d;
    font-family: "Cormorant", serif;
    font-size: 4em;
}

/* List elements (ul, ol, li) */
ul,
ol {
    padding-left: 20px;
}

li {
    color: var(--text-color);
}

/* Table */
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    color: var(--text-color);
}

.buttonVariation1 {
    border: 1px solid #1d1d1d;
    padding: 16px 30px;
    border-radius: 40px;
    color: #1d1d1d;
    font-size: 1.2em;
    background-color: transparent;
    transition: 0.3s ease;
}
.buttonVariation1:hover {
    background-color: #bf5331;
    color: black;
}

.buttonVariation2 {
    border: 1px solid #bf5331;
    padding: 16px 30px;
    border-radius: 4px;
    color: #bf5331;
    font-size: 1.2em;
    background-color: transparent;
    transition: 0.3s ease;
}
.buttonVariation2:hover {
    background-color: #bf5331;
    color: rgb(255, 255, 255);
}

/* Light/Dark Mode Styles */
body .light-mode {
    background-color: var(--background-color-light);
    color: var(--text-color-light);
    --header-color: var(
        --header-color-light
    ); /* Color for headers in light mode */
    --text-color: var(
        --text-color
    ); /* Slightly gray color for text in light mode */
}

body .dark-mode {
    background-color: var(--background-color-dark);
    color: var(--text-color-dark);
    --header-color: var(
        --header-color-dark
    ); /* Color for headers in dark mode */
    --text-color: var(
        --text-color-dark
    ); /* Light color for text in dark mode */
}

.teamBackground {
    background-image: url("./assets/images/team/teambg.png");
}
.bankmainBackground {
    background-image: url("./assets/images/bankmain.jfif");
    background-size: cover; /* Ensure the background image fits within the container */
    background-position: center;
    background-repeat: no-repeat;
    overflow: auto;
}
/* Hide scrollbar but allow scrolling */
#bank::-webkit-scrollbar {
    display: none;
}

#bank {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 1400px) {
    /* For tablets */
    h1 {
        font-size: 4.5em;
    } /* Smaller than original */
    h2 {
        font-size: 3em;
    }
    h3 {
        font-size: 2.75em;
    }
    h4 {
        font-size: 2em;
    }
    h5 {
        font-size: 1.75em;
    }
    h6 {
        font-size: 1.3em;
    }
    p,
    span,
    li {
        font-size: 1.1em; /* Slightly smaller */
    }
    .buttonVariation1 {
        padding: 12px 26px;
        border-radius: 40px;

        font-size: 2em;
    }
}

@media (max-width: 1024px) {
    /* For tablets */
    h1 {
        font-size: 3em;
    } /* Smaller than original */
    h2 {
        font-size: 2em;
    }
    h3 {
        font-size: 1.75em;
    }
    h4 {
        font-size: 1.5em;
    }
    h5 {
        font-size: 1.25em;
    }
    h6 {
        font-size: 1em;
    }
    p,
    span,
    li {
        font-size: 1.1em; /* Slightly smaller */
    }
    .buttonVariation1 {
        padding: 8px 20px;
        border-radius: 40px;

        font-size: 1.6em;
    }
}

@media (max-width: 768px) {
    /* For tablets */
    h1 {
        font-size: 2em;
    } /* Smaller than original */
    h2 {
        font-size: 1.75em;
    }
    h3 {
        font-size: 1.5em;
    }
    h4 {
        font-size: 1.25em;
    }
    h5 {
        font-size: 1em;
    }
    h6 {
        font-size: 0.85em;
    }
    p,
    span,
    li {
        font-size: 0.9em; /* Slightly smaller */
    }
    .buttonVariation1 {
        padding: 6px 15px;
        border-radius: 40px;
        color: white;
        font-size: 1.2em;
    }
}

@media (max-width: 480px) {
    /* For mobile phones */
    h1 {
        font-size: 1.5em;
    } /* Smaller than tablet */
    h2 {
        font-size: 1.25em;
    }
    h3 {
        font-size: 1.1em;
    }
    h4 {
        font-size: 1em;
    }
    h5 {
        font-size: 0.9em;
    }
    h6 {
        font-size: 0.75em;
    }
    p,
    span,
    li {
        font-size: 0.85em; /* Smaller than tablet */
    }
}

.panel {
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.red {
    height: 100vh;
    min-height: 100vh;
    background-color: red;
}
.dark {
    height: 100vh;
    min-height: 100vh;
    background-color: rgb(18, 18, 18);
}
.orange {
    height: 100vh;
    min-height: 100vh;
    background-color: rgb(232, 97, 13);
}
.purple {
    height: 50vh;
    min-height: 50vh;
    background-color: purple;
}

/* .loreBg {
    background-image: url("./assets//images/Newspaper.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.boxes-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

#smooth-content {
    overflow: visible;
    width: 100%;
    /* set a height because the contents are position: absolute, thus natively there's no height */
    height: 40000px;
}
.box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

.box.active {
    border: solid 2px red;
}

.box-a {
    top: 200px;
}

.box-b {
    top: 600px;
}

.box-c {
    top: 1000px;
}
.end {
    position: absolute;
    /*   bottom: 0; */
    top: 2400px;
    transform: translateY(-100%);
    font-size: 30px;
    color: white;
}
@keyframes rotateCircular {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }
    25% {
        transform: rotateX(20deg) rotateY(20deg);
    }
    50% {
        transform: rotateX(0deg) rotateY(40deg);
    }
    75% {
        transform: rotateX(-20deg) rotateY(20deg);
    }
    100% {
        transform: rotateX(0deg) rotateY(0deg);
    }
}

.rotatingCircular {
    animation: rotateCircular 4s linear infinite;
}
.layer {
    width: 100%;
    height: 100%;
    overflow: hidden; /* Prevents any overflow from the scaled image */
}
#scene {
    width: 100vw;
    height: 100vh;
    overflow: hidden; /* Prevents any overflow (like white space) from being visible */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.css-futumu-MuiLinearProgress-root span {
    color: rgb(221, 221, 221);
}

.linearBg {
    position: relative;
    z-index: 1;

    background-attachment: fixed; /* Key for parallax effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; /* Cover ensures the background covers the div */
    background-image: linear-gradient(
        to right top,
        #220530,
        #1a0930,
        #130b2e,
        #0c0d2b,
        #060e28,
        #020f25,
        #001022,
        #00101e,
        #00101a,
        #000f16,
        #000e11,
        #010c0c
    );
    /* background-image: linear-gradient(
        to right top,
        #1d1c1c,
        #20191b,
        #22161c,
        #201420,
        #1b1326,
        #141427,
        #0b1428,
        #001527,
        #001421,
        #00131a,
        #001013,
        #010c0c
    ); */
}
.teamCardBg {
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 32px 0 #c5fcfc;
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);

    animation: circularMotion 5s infinite linear;
}

/* Base state: applying transition properties here ensures smooth changes */
.navbarBase {
    transition: background 0.3s ease, box-shadow 0.3s ease,
        backdrop-filter 0.3s ease, border-bottom 0.3s ease;
    background: transparent; /* Default state */
    box-shadow: none; /* Default state */
    backdrop-filter: none; /* Default state */
    border-bottom: transparent; /* Default state */
    /* Apply other base styles here */
}

/* Scrolled state */
.navbarBg {
    background: rgba(0, 0, 0, 0.15);
    /* box-shadow: 0 0px 12px 0 #bf5331; */
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    /* Remove or adjust the animation property if it interferes with the transition */
    /* animation: navbarMotion 5s infinite linear; */
}
.scroll-container {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

@keyframes navbarMotion {
    0% {
        border-bottom: 3px solid rgba(31, 38, 135, 0.37);
        box-shadow: 10px 0 302px 0 rgba(31, 38, 135, 0.37);
    }
    25% {
        border-bottom: 3px solid rgba(31, 38, 135, 0.37);
        box-shadow: 0 10px 32px 0 rgba(31, 38, 135, 0.37);
    }
    50% {
        border-bottom: 3px solid rgba(31, 114, 135, 0.37);
        box-shadow: -10px 0 302px 0 rgba(31, 114, 135, 0.37);
    }
    75% {
        border-bottom: 3px solid rgba(111, 31, 135, 0.37);
        box-shadow: -10px 0 302px 0 rgba(111, 31, 135, 0.37);
    }
    100% {
        border-bottom: 3px solid rgba(31, 38, 135, 0.37);
        box-shadow: -10px 0 302px 0 rgba(31, 38, 135, 0.37);
    }
}
