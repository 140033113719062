.background-containerBank {
    position: relative;
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
    background-image: url("../../../../assets/images/bankbg.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%;
}
#opening::-webkit-scrollbar {
    display: none;
}

#opening {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.background-paper {
    position: relative;
    width: 100%;
    padding: 25px 35px 25px 35px;
    background-image: url("../../../../assets/images/paper4.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}
.background-star {
    position: relative;
    width: 100%;
    padding: 25px 35px 25px 35px;
    background-image: url("../../../../assets/images/star.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
   
}
.background-referral {
    position: relative;
    width: 100%;
    padding: 25px 35px 25px 35px;
    background-image: url("../../../../assets/images/questalone.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
   
}
.background-questreferral {
    position: relative;
    width: 100%;
    height: 500px; /* Ensure the container has a fixed height */
    padding: 25px 35px;
    background-image: url("../../../../assets/images/questalone.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%; /* Stretch the image to fill the container */
}

.background-paper-navbar {
    position: relative;
    width: 100%;
    padding: 25px 35px 25px 35px;

    /* background-color: black; */
    background-image: url("../../../../assets/images/navbarpaper.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}
.background-paper-connect {
    position: relative;
    width: 100%;
    padding: 10px 65px 10px 55px;
    background-image: url("../../../../assets/images/paper.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}
.background-containerBridge {
    position: relative;
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
    background-image: url("../../../../assets/images/bridgeviewbg.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%;
}

.background-container {
    position: relative;
    width: 100vw; /* 100% of the viewport width */
    height: 100vh; /* 100% of the viewport height */
    background-image: url("../../../../assets/images/newmain.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 100% 100%;
    transition: transform 0.3s ease;
    overflow: hidden;
}
.cursiveHandwriting {
    /* font-family: "Dancing Script", cursive; */
    /* font-family: "Satisfy", cursive; */
    font-family: "Cormorant Garamond", serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
}

.bankDiscordButton {
    padding: 10px;
    background-color: #5864f8;
    border-radius: 15px;
    color: white;
    font-size: 40px;
}

.bankModalBg {
    background: rgba(17, 17, 17, 0.7);
    box-shadow: 0 4px 16px 0 #bf5331;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
    border: 10px solid #bf5331;
}

.interactive-area {
    position: absolute;
    opacity: 100;

    cursor: url("../../../../assets/icons/glasses.svg") 4 4, auto;
    width: 300px; /* or your desired size */
    height: 300px; /* must be equal to width for a perfect circle */
    border-radius: 100%; /* makes the div circular */
    background-color: rgba(255, 0, 0, 0);
    /* border: 1px solid gray; */
    /* background-color: red; */
    backdrop-filter: blur(0); /* no blur by default */
    overflow: hidden; /* ensures child elements don't overflow */
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease,
        background-color 0.3s ease, backdrop-filter 0.3s ease, border 0.3s ease;
}

#bank {
    touch-action: none;
}
#bankview {
    touch-action: none;
}
#bridgeview {
    touch-action: none;
}
/* #bankcasinoview {
    touch-action: none;
}
#bankwhitelistview {
    touch-action: none;
}
#bankslotmachineview {
    touch-action: none;
} */
@media (max-width: 648px) {
    .background-container {
        width: 2000px;
        background-position: left;
        background-size: cover;
    }
    .cursiveHandwriting {
        font-size: 10px;
    }
    .background-paper-navbar {
        background-size: fill;
    }
}
@media (max-width: 768px) {
    .cursiveHandwriting {
        font-size: 14px;
    }

}
@media (max-width: 1024px) {
    .cursiveHandwriting {
        font-size: 18px;
    }
}
@media (min-width: 1700px) {
    .deadbodylocation {
        left: 25%;
        position: absolute;
    }
}
@media (max-width: 648px) {
    .cursiveHandwriting {
        font-size: 13px;
    }
}
